// Chakra Imports
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { fetchImage } from "../../redux/imageSlice";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import defaultImageUrl from 'assets/img/zindaa logo-black-01.png';
export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const [language, setLanguage] = useState("en");
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');

    if (storedLang) {
      setLanguage(storedLang);
      i18n.changeLanguage(storedLang);
    }

    window.addEventListener("scroll", changeNavbar);
    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, [i18n]);

  const {
    secondary,
    message,
    brandText,
    under,
    setOpenSidebar,
    openSidebar,
    largeLogo,
    routes,
  } = props;

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue("#fff", "rgba(11,20,55,0.5)");
  let navbarBorder = "transparent";
  let secondaryMargin = "-9px";
  let paddingX = "15px";
  let gap = "0px";
  let size = "sm";

  return (
    <>
      <Helmet>
        <title itemProp="name">Zindaa - {t("Dashboard")}</title>
      </Helmet>
      <Box
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition="center"
        backgroundSize="cover"
        borderWidth="1.5px"
        borderStyle="solid"
        zIndex={1}
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
        transition-property="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems={{ xl: "center" }}
        display={secondary ? "block" : "flex"}
        minH="75px"
        justifyContent={{ xl: "center" }}
        lineHeight="25.6px"
        mx="auto"
        mt={secondaryMargin}
        pb="6px"
        right={{ base: "0px" }}
        px={{
          sm: paddingX,
          md: "10px",
        }}
        ps={{
          xl: "12px",
        }}
        pt="8px"
        top={{ base: "0px" }}
        w={{
          base: "100vw",
        }}
        sx={{ boxShadow: "14px 17px 40px 4px rgba(112, 144, 176, 0.08)" }}
      >
        <Flex
          w="100%"
          flexDirection={{
            sm: "column",
            md: "row",
          }}
          alignItems={{ xl: "center" }}
          mb={gap}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Flex
              me={openSidebar ? "" : "5"}
              mx={openSidebar ? "14" : "1"}
              display={{ sm: "none", xl: "flex" }}
            >
              {largeLogo &&
              (largeLogo[0]?.logoLgImg || largeLogo[0]?.logoSmImg) ? (
                <Image
                  style={{
                    width: openSidebar ? "165px" : "60px",
                    height: "52px",
                    objectFit: "contain",
                  }}
                  src={
                    openSidebar
                      ? largeLogo[0]?.logoLgImg || defaultImageUrl
                      : largeLogo[0]?.logoSmImg || defaultImageUrl
                  }
                  alt="Logo"
                  cursor="pointer"
                  onClick={() => !props.from && setOpenSidebar(!openSidebar)}
                  userSelect="none"
                  my={2}
                />
              ) : (
                <Image
                  style={{
                    width: openSidebar ? "165px" : "60px",
                    height: "52px",
                    objectFit: "contain",
                  }}
                  src={defaultImageUrl}
                  alt="Default Logo"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.style.display = "none";
                  }}
                  cursor="pointer"
                  onClick={() => !props.from && setOpenSidebar(!openSidebar)}
                  userSelect="none"
                  my={2}
                />
              )}
              {(!largeLogo ||
                !(largeLogo[0]?.logoLgImg || largeLogo[0]?.logoSmImg)) &&
                !defaultImageUrl && (
                  <Heading
                    my={4}
                    style={{
                      width: openSidebar ? "165px" : "60px",
                      height: "52px",
                      objectFit: "contain",
                    }}
                    cursor={"pointer"}
                    onClick={() => !props.from && setOpenSidebar(!openSidebar)}
                    userSelect={"none"}
                  >
                    {openSidebar ? "Zindaa" : "ZA"}
                  </Heading>
                )}
            </Flex>
            <Box
              display={{ sm: "none", xl: "flex" }}
              ms={openSidebar ? "" : "3"}
              onClick={() => setOpenSidebar(!openSidebar)}
              style={{ fontSize: "25px" }}
            >
              {openSidebar ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
            </Box>
            <Link color={mainText} display={{ sm: "flex", xl: "none" }}>
              {largeLogo && largeLogo[0]?.logoLgImg ? (
                <Image
                  style={{ width: "100%", height: "52px" }}
                  src={largeLogo[0]?.logoLgImg}
                  alt="Logo"
                  cursor="pointer"
                  userSelect="none"
                  my={2}
                />
              ) : (
                <Heading my={4} cursor={"pointer"} userSelect={"none"}>
                  {openSidebar === true ? "Zindaa" : "ZA"}
                </Heading>
              )}
            </Link>
            <Link
              color={mainText}
              href="#"
              pt="2px"
              bg="inherit"
              ps="30px"
              display={{ sm: "none", xl: "flex" }}
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              textTransform={"capitalize"}
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              {t(brandText)}
            </Link>
          </Box>
          <Box
            ms="auto"
            w={{ sm: "100%", md: "unset" }}
            display="flex"
            alignItems="center"
          >
            <Menu>
              <MenuButton
                as={Button}
                ml={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
                minWidth="120px"
                margin="0.5rem"
                marginTop="1rem"
                borderRadius="12px"
                variant="solid"
              >
                <Flex align="center">
                  <Box
                    as="span"
                    borderRadius="full"
                    overflow="hidden"
                    display="inline-block"
                    mr={2}
                    boxSize="20px"
                  >
                    <Image
                      src={
                        language === "en"
                          ? "https://flagcdn.com/w20/us.png"
                          : "https://flagcdn.com/w20/pe.png"
                      }
                      alt={language === "en" ? "US Flag" : "Peruvian Flag"}
                      boxSize="100%"
                    />
                  </Box>
                  <Text>{language === "en" ? "English" : "Español"}</Text>
                </Flex>
              </MenuButton>

              <MenuList>
                <MenuItem onClick={() => handleLanguageChange("en")}>
                  <Flex align="center">
                    <Box
                      as="span"
                      borderRadius="full"
                      overflow="hidden"
                      display="inline-block"
                      mr={2}
                      boxSize="20px"
                    >
                      <Image
                        src="https://flagcdn.com/w20/us.png"
                        alt="US Flag"
                        boxSize="100%"
                      />
                    </Box>
                    English
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange("es")}>
                  <Flex align="center">
                    <Box
                      as="span"
                      borderRadius="full"
                      overflow="hidden"
                      display="inline-block"
                      mr={2}
                      boxSize="20px"
                    >
                      <Image
                        src="https://flagcdn.com/w20/pe.png"
                        alt="Peruvian Flag"
                        boxSize="100%"
                      />
                    </Box>
                    Español
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>

            <AdminNavbarLinks
              setOpenSidebar={setOpenSidebar}
              openSidebar={openSidebar}
              onOpen={props.onOpen}
              logoText={props.logoText}
              secondary={props.secondary}
              fixed={props.fixed}
              scrolled={scrolled}
              routes={routes}
            />
          </Box>
        </Flex>
        {secondary ? <Text color="white">{message}</Text> : null}
      </Box>
    </>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
