import i18 from "i18next";
import LanguageDetetctor from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18.use(LanguageDetetctor).use(initReactI18next).use(Backend).init({
    lng:"en",
    fallbackLng: "en",
    //debug: true,
    returnObjects: true,
    react: { 
        useSuspense: false 
      }
});

