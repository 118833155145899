import { useEffect, useState } from "react";
import { useParams,useLocation,useNavigate } from "react-router-dom";
import { getApi } from "services/api";
import { Box, Spinner, Text,Link } from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";

const IframeComponent = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await getApi('api/custom-links/view/', id);
            if (response?.data) {
                setData(response.data);
            } else {
                setError("url incorrect");
            }
        } catch (err) {
            setError("Failed to fetch data");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    if (loading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
                <Spinner size="xl" />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
                <Text color="red.500">{error}</Text>
            </Box>
        );
    }

    return (
      <Box width="100%" height="100vh" position="relative">
        <iframe
          src={data?.url || ""}
          style={{ width: '100%', height: '100%' }}
          frameBorder="0"
          title="Iframe"
        />
        <Box
          onClick={() => navigate('/default')}
          position="absolute"
          top="10px"
          left="10px"
          p="10px"
          bg="white"
          borderRadius="md"
          boxShadow="md"
          cursor="pointer"
        >
          <IoIosArrowBack size="24px" />
        </Box>
      </Box>
    );
  };


export default IframeComponent;
