import { Image, Box } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
const DataNotFound = () => {
    const { t } = useTranslation()
    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {t("-- No Data Found --")}
        </Box>
    )
}

export default DataNotFound
