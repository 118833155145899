import { CloseIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Button,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  Box,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Select,
  Switch,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import Spinner from "components/spinner/Spinner";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { userSchema } from "schema";
import { putApi, postApi, getApi } from "services/api"; 
import { useTranslation } from "react-i18next";
import * as GrIcons from "react-icons/gr";
import Select2 from "react-select"; 


const iconOptions = Object.keys(GrIcons).map((iconName) => {
  const IconComponent = GrIcons[iconName];
  return {
    value: iconName,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconComponent style={{ marginRight: 8, fontSize: 20 }} />
        {iconName}
      </div>
    ),
    icon: iconName 
  };
});
const AddEditCustomLinks = (props) => {
  const {
    onClose,
    isOpen,
    setAction,
    data,
    userAction,
    userData,
    selectedId,
    fetchData,
    setUserAction,
  } = props;
  const [isLoding, setIsLoding] = useState(false);
  const [show, setShow] = React.useState(false);
  const [showOnAllAccounts, setShowOnAllAccounts] = useState(true);
  const [permissions, setPermissions] = useState({
    camera: false,
    microphone: false,
  });
  const [userList, setUserList] = useState([]);
  const showPass = () => setShow(!show);
  const { t } = useTranslation();

  useEffect(() => {
    if (!showOnAllAccounts) {
      fetchUserList();
    }
  }, [showOnAllAccounts]);

  const fetchUserList = async () => {
    try {
      const response = await getApi("api/user/");
      if (response && response.status === 200) {
        setUserList(response.data.user);
      } else {
        toast.error(response.response.data?.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initialValues = {
    linkTitle: userAction === "add" ? "" : data?.linkTitle,
    icon: userAction === "add" ? "" : data?.icon,
    url: userAction === "add" ? "" : data?.url,
    openBehavior: userAction === "add" ? "Iframe" : data?.openBehavior,
    users: userAction === "add" ? [] : data?.users,
    showOnAllAccounts: userAction === "add" ? true : data?.showOnAllAccounts,
    permissions: {
      camera: userAction === "add" ? false : data?.permissions?.camera,
      microphone: userAction === "add" ? false : data?.permissions?.microphone,
    },
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      AddData(values);
    },
  });

  useEffect(() => {
    setPermissions(formik.values.permissions);
  }, [formik.values.permissions]);
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;

  useEffect(() => {
    if (userAction === "edit" && data) {
      setShowOnAllAccounts(data.showOnAllAccounts);
      setFieldValue("showOnAllAccounts", data.showOnAllAccounts);
    }
  }, [userAction, data]);

  const handleShowOnAllAccountsChange = () => {
    const newValue = !values.showOnAllAccounts;
    setShowOnAllAccounts(newValue);
    setFieldValue("showOnAllAccounts", newValue);

    if (newValue) {
      // Clear users array if "Show on All Accounts" is true
      setFieldValue("users", []);
    }
  };

  const handlePermissionsChange = (permission) => {
    setFieldValue("permissions", {
      ...values.permissions,
      [permission]: !values.permissions[permission],
    });
  };

  const AddData = async (values) => {
    const payload = {
      ...values,
      showOnAllAccounts: values.showOnAllAccounts,
      permissions: values.permissions,
    };

    if (userAction === "add") {
      try {
        setIsLoding(true);
        let response = await postApi("api/custom-links/add", payload);
        if (response && response.status === 200) {
          toast.success(response.data?.message);
          resetForm();
          onClose();
          setUserAction("");
          setAction((pre) => !pre);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          toast.error(response.response.data?.message);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoding(false);
      }
    } else if (userAction === "edit") {
      try {
        setIsLoding(true);
        let response = await putApi(
          `api/custom-links/edit/${selectedId}`,
          payload
        );
        if (response && response.status === 200) {
          fetchData();
          let updatedUserData = userData;
          if (updatedUserData?._id === selectedId) {
            if (updatedUserData && typeof updatedUserData === "object") {
              updatedUserData = {
                ...updatedUserData,
                ...payload,
              };
            }
            const updatedDataString = JSON.stringify(updatedUserData);
            localStorage.setItem("user", updatedDataString);
          }
          toast.success(response.data?.message);
          onClose();
          setUserAction("");
          setAction((pre) => !pre);
          window.location.reload();
        } else {
          toast.error(response.data?.message);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoding(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader justifyContent="space-between" display="flex">
          {userAction === "add" ? t("Create") : t("Edit")}{" "}
          {t("Custom Menu Link")}
          <IconButton onClick={onClose} icon={<CloseIcon />} />
        </ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap={3}>
            <GridItem colSpan={{ base: 12 }}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                {t("Link Title")}
              </FormLabel>
              <Input
                fontSize="sm"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.linkTitle}
                name="linkTitle"
                placeholder={t("Link Title")}
                fontWeight="500"
                borderColor={
                  errors.linkTitle && touched.linkTitle ? "red.300" : null
                }
              />
              <Text mb="10px" color={"red"}>
                {" "}
                {errors.linkTitle && touched.linkTitle && errors.linkTitle}
              </Text>
            </GridItem>

            <GridItem colSpan={{ base: 12 }}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                {t("Icon")}
                <Text color={"red"}>*</Text>
              </FormLabel>
              <Select2
                placeholder={t("Select Icon")}
                onChange={(selectedOption) => setFieldValue("icon", selectedOption.value)}
                value={iconOptions.find(option => option.value === values.icon)}
                name="icon"
                fontSize="sm"
                borderColor={errors.icon && touched.icon ? "red.300" : null}
                options={iconOptions}
              />
              <Text mb="10px" color={"red"}>
                {errors.icon && touched.icon && errors.icon}
              </Text>
            </GridItem>
            <GridItem colSpan={{ base: 12 }}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                {t("URL")}
                <Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                fontSize="sm"
                type="url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                name="url"
                placeholder={t("URL")}
                fontWeight="500"
                borderColor={errors.url && touched.url ? "red.300" : null}
              />
              <Text mb="10px" color={"red"}>
                {errors.url && touched.url && errors.url}
              </Text>
            </GridItem>
            <GridItem colSpan={{ base: 12 }}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                {t("Sidebar Preference")}
              </FormLabel>
              <Switch
                isChecked={values.showOnAllAccounts}
                onChange={handleShowOnAllAccountsChange}
                colorScheme="teal"
              />{" "}
              {t("Show Link on All Accounts")}
              {!values.showOnAllAccounts && (
                <>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    mb="8px"
                  >
                    {t("Select Users")}
                  </FormLabel>
                  <Select2
                    isMulti
                    name="users"
                    options={userList.map((user) => ({
                      value: user._id,
                      label: user.username,
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOptions) =>
                      setFieldValue(
                        "users",
                        selectedOptions.map((option) => option.value)
                      )
                    }
                    value={userList
                      .filter((user) => values.users.includes(user._id))
                      .map((user) => ({
                        value: user._id,
                        label: user.username,
                      }))}
                  />
                </>
              )}
            </GridItem>
            <GridItem colSpan={{ base: 12 }}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                {t("When Clicked")}
              </FormLabel>
              <RadioGroup
                onChange={(value) => setFieldValue("openBehavior", value)}
                value={values.openBehavior}
              >
                <Box mb={2}>
                  <Radio value="Iframe">
                    {t("Open in IFrame inside the platform")}
                  </Radio>
                </Box>
                <Box mb={2}>
                  <Radio value="New Tab">{t("Open in New Tab")}</Radio>
                </Box>
                <Box>
                  <Radio value="Current Tab">{t("Open in Current Tab")}</Radio>
                </Box>
              </RadioGroup>
            </GridItem>

            <GridItem colSpan={{ base: 12 }}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                {t("Permissions")}
              </FormLabel>
              <Switch
                isChecked={formik.values.permissions.camera}
                onChange={() => handlePermissionsChange("camera")}
                name="camera"
                colorScheme="teal"
              />{" "}
              {t("Allow Camera")}
              <Switch
                isChecked={formik.values.permissions.microphone}
                onChange={() => handlePermissionsChange("microphone")}
                name="microphone"
                colorScheme="teal"
                ml={4}
              />{" "}
              {t("Allow Microphone")}
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="brand"
            size="sm"
            disabled={isLoding ? true : false}
            onClick={handleSubmit}
          >
            {isLoding ? <Spinner /> : t("Save")}
          </Button>
          <Button
            sx={{ marginLeft: 2, textTransform: "capitalize" }}
            variant="outline"
            colorScheme="red"
            size="sm"
            onClick={() => {
              formik.resetForm();
              onClose();
            }}
          >
            {t("Close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEditCustomLinks;
