import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import Spinner from 'components/spinner/Spinner';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {useTranslation} from "react-i18next";
const CommonDeleteModel = (props) => {
    const { isOpen, onClose, type, handleDeleteData, ids, selectedValues } = props
    const [isLoding, setIsLoding] = useState(false)
    const {t}=useTranslation()
    const translatedType = t(type);
    const handleDelete = () => {
        handleDeleteData(ids, selectedValues)
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <div>
            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>  {t('deleteTitle', { type: translatedType })}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    {t('deleteText', { type: translatedType })}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" size="sm" mr={2} onClick={handleDelete} disabled={isLoding ? true : false} >{isLoding ? <Spinner /> : t('Yes')}</Button>
                        <Button variant="outline" size="sm" onClick={handleClose}>{t("No")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default CommonDeleteModel
