import * as yup from 'yup'
import {t} from "i18next";

export const propertySchema = yup.object({
    name: yup.string().required(t('Name is required')),
    description: yup.string().required(t('Description is required')),
    operationType: yup.string().required(t('Operation type is required')),
    propertyType: yup.string().required(t('Property type is required')),
    aboutProperty: yup.string().required(t('About property is required')),
    totalArea: yup.number().required(t('Total area is required')).positive().integer(),
    roofedArea: yup.number().required(t('Roofed area is required')).positive().integer(),
    priceUSD: yup.number().required(t('Price, USD (Sell) is required')).positive(),
    maintenanceFeesPENSell: yup.number().required(t('Price of maintenance fees, PEN (Sell) is required')).positive(),
    priceMonthUSD: yup.number().required(t('Price for the month, USD (Rent) is required')).positive(),
    maintenanceFeesPENRent: yup.number().required(t('Price of maintenance fees, PEN (Rent) is required')).positive(),
    location: yup.string().required(t('Location is required')),
    country: yup.string().required(t('Country is required')),
    state: yup.string().required(t('State is required')),
    province: yup.string().required(t('Province is required')),
    bedrooms: yup.string().required(t('Bedrooms is required')),
    bathrooms: yup.string().required(t('Bathrooms is required')),
    halfBathrooms: yup.string().required(t('Half bathrooms is required')),
    totalFloors: yup.string().required(t('Total floors of the building is required')),
    apartmentFloor: yup.string().required(t('Apartment floor is required')),
    parking: yup.string().required(t('Parking is required')),
    parkingForVisitors: yup.string().required(t('Parking for visitors is required')),
    parkAccess: yup.boolean(),
    elevators: yup.boolean(),
    library: yup.boolean(),
    soccerField: yup.boolean(),
    tennisCourt: yup.boolean(),
    sportsCenter: yup.boolean(),
    clubhouse: yup.boolean(),
    diningRoom: yup.boolean(),
    concierge: yup.boolean(),
    familyRoom: yup.boolean(),
    gym: yup.boolean(),
    jacuzzi: yup.boolean(),
    privateGarden: yup.boolean(),
    swimmingPool: yup.boolean(),
    reception: yup.boolean(),
    gameRoom: yup.boolean(),
    meetingRoom: yup.boolean(),
    sauna: yup.boolean(),
    basement: yup.boolean(),
    terrace: yup.boolean(),
    bbqArea: yup.boolean(),
    securitySystem: yup.boolean(),
    waterTank: yup.boolean(),
    highCeilings: yup.boolean(),
    telephone: yup.boolean(),
    electricHeater: yup.boolean(),
    terraceInUnit: yup.boolean(),
    triplex: yup.boolean(),
    quietLocation: yup.boolean(),
    acousticWindows: yup.boolean(),
    cityView: yup.boolean(),
    golfView: yup.boolean(),
    interiorView: yup.boolean(),
    seaView: yup.boolean(),
    walkInCloset: yup.boolean(),
    wifi: yup.boolean(),
    centralArea: yup.boolean(),
    serviceRoom: yup.boolean(),
    duplex: yup.boolean(),
    ecoFriendly: yup.boolean(),
    electricity: yup.boolean(),
    equipped: yup.boolean(),
    linearParking: yup.boolean(),
    parallelParking: yup.boolean(),
    parkFront: yup.boolean(),
    naturalGas: yup.boolean(),
    securityGuard: yup.boolean(),
    kitchenette: yup.boolean(),
    laundryRoom: yup.boolean(),
    livingRoom: yup.boolean(),
    whiteLineAppliances: yup.boolean(),
    kitchenFurniture: yup.boolean(),
    penthouse: yup.boolean(),
    securityPersonnel: yup.boolean(),
    poolInUnit: yup.boolean(),
    renovated: yup.boolean(),
    basicServices: yup.boolean(),
    musicSystem: yup.boolean(),
    beachAccess: yup.boolean(),
    airConditioning: yup.boolean(),
    fireAlarm: yup.boolean(),
    storage: yup.boolean(),
    highRentalPotential: yup.boolean(),
    furnished: yup.boolean(),
    suitableForCommercialUse: yup.boolean(),
    builtInWardrobes: yup.boolean(),
    serviceBathroom: yup.boolean(),
    cable: yup.boolean(),
    heating: yup.boolean(),
    smartHome: yup.boolean(),
    fireplace: yup.boolean(),
    openKitchen: yup.boolean(),
    kitchenWithIsland: yup.boolean(),
    condominium: yup.boolean(),
    recentConstruction: yup.boolean(),
})