import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from '@chakra-ui/react';
import { getApi } from 'services/api';
import CommonCheckTable from '../../../components/checkTable/checktable';
import { SearchIcon } from "@chakra-ui/icons";
import { CiMenuKebab } from 'react-icons/ci';
import { IoIosArrowBack } from 'react-icons/io';
import customLinksAdvanceSearch from './components/customLinkAdvanceSearch';
import { deleteManyApi } from 'services/api';
import CommonDeleteModel from 'components/commonDeleteModel';
import AddEditCustomLinks from './AddEditCustomLinks';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { toast } from "react-toastify";
const Index = () => {
    const title = "Custom Links";
    const [action, setAction] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editData, setEditData] = useState({});
    // const { onOpen, onClose } = useDisclosure();
    const [isOpen, setIsOpen] = useState(false)
    const [selectedId, setSelectedId] = useState();
    const [deleteMany, setDelete] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [advanceSearch, setAdvanceSearch] = useState(false);
    const [getTagValuesOutSide, setGetTagValuesOutside] = useState([]);
    const [searchboxOutside, setSearchboxOutside] = useState('');
    const navigate = useNavigate();
    const [isLoding, setIsLoding] = useState(false);
    const [data, setData] = useState([]);
    const [displaySearchData, setDisplaySearchData] = useState(false);
    const [searchedData, setSearchedData] = useState([]);
    const [userAction, setUserAction] = useState('')
    const { t } = useTranslation();
    const tableColumns = [
        { Header: "#", accessor: "_id", isSortable: false, width: 10 },
        { Header: 'Link Title', accessor: 'linkTitle',},
        { Header: "URL", accessor: "url", },
        {
            Header: "Show on All Accounts",
            accessor: "showOnAllAccounts",
            cell: ({ value }) => (value ? "Yes" : "No"), // Convert true/false to Yes/No
          },
        { Header: "Open Behavior", accessor: "openBehavior", },
        {
            Header: "Action", accessor: 'action', isSortable: false, center: true,
            cell: ({ row }) => (
                <Text fontSize="md" fontWeight="900" textAlign={"center"}>
                    <Menu isLazy  >
                        <MenuButton><CiMenuKebab /></MenuButton>
                        <MenuList minW={'fit-content'} transform={"translate(1520px, 173px);"}>
                            <MenuItem py={2.5} onClick={() => { setEditData(row?.original); setIsOpen(true); setSelectedId(row?.original._id); setUserAction('edit') }} icon={<EditIcon mb={1} fontSize={15} />}>{t("Edit")}</MenuItem>
                            {row?.original?.role === 'superAdmin' ? '' : <MenuItem py={2.5} color={'red'} onClick={() => { setSelectedValues([row?.original._id]); setDelete(true) }} icon={<DeleteIcon fontSize={15} />}>{t("Delete")}</MenuItem>}
                        </MenuList>
                    </Menu>
                </Text>
            )
        },
    ];

    const [columns, setColumns] = useState([...tableColumns]);
    const [selectedColumns, setSelectedColumns] = useState([...tableColumns]);
    const dataColumn = tableColumns?.filter(item => selectedColumns?.find(colum => colum?.Header === item.Header))

    const handleOpen = () => {
        setUserAction('add')
        setIsOpen(true)
    }
    const handleClose = () => {
        setIsOpen(false)
        fetchData();
    }

    const fetchData = async () => {
        setIsLoding(true)
        let result = await getApi('api/custom-links/');
        setData(result?.data?.customLinks);
        setIsLoding(false)
    }

    const handleDeleteClick = async () => {

        try {
            setIsLoding(true)
            let response = await deleteManyApi(`api/custom-links/deleteMany`, selectedValues)
            if (response.status === 200) {
                toast.success(response.data?.message);
                setSelectedValues([])
                setDelete(false)
                setAction((pre) => !pre)
            }
        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoding(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [action])

    return (
        <>
        <Helmet>
				<title itemProp="name">Zindaa - {t(title)}</title>
		</Helmet>
        <div>
            <CommonCheckTable
                title={t(title)}
                isLoding={isLoding}
                columnData={columns ?? []}
                dataColumn={dataColumn ?? []}
                allData={data ?? []}
                tableData={displaySearchData ? searchedData : data}
                searchDisplay={displaySearchData}
                setSearchDisplay={setDisplaySearchData}
                searchedDataOut={searchedData}
                setSearchedDataOut={setSearchedData}
                tableCustomFields={[]}
                action={action}
                setAction={setAction}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                isOpen={isOpen}
                onClose={onclose}
                access={{
                    create : true,
                    edit : true,
                    delete : true,
                    view : true,
                }}
                onOpen={handleOpen}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setDelete={setDelete}
                BackButton={<Button onClick={() => navigate('/admin-setting')} variant="brand" size="sm" leftIcon={<IoIosArrowBack />} ml={2}>{t("Back")}</Button>}
                AdvanceSearch={
                    <Button variant="outline" colorScheme='brand' leftIcon={<SearchIcon />} mt={{ sm: "5px", md: "0" }} size="sm" onClick={() => setAdvanceSearch(true)}>{t("Advance Search")}</Button>
                }
                getTagValuesOutSide={getTagValuesOutSide}
                searchboxOutside={searchboxOutside}
                setGetTagValuesOutside={setGetTagValuesOutside}
                setSearchboxOutside={setSearchboxOutside}
            />
            <AddEditCustomLinks isOpen={isOpen} onClose={handleClose} data={editData} selectedId={selectedId} userAction={userAction} setUserAction={setUserAction} fetchData={fetchData} />
            <CommonDeleteModel isOpen={deleteMany} onClose={() => setDelete(false)} type='Custom Links' handleDeleteData={handleDeleteClick} ids={''} selectedValues={selectedValues} />

            <customLinksAdvanceSearch
                advanceSearch={advanceSearch}
                setAdvanceSearch={setAdvanceSearch}
                setSearchedData={setSearchedData}
                setDisplaySearchData={setDisplaySearchData}
                allData={data ?? []}
                setAction={setAction}
                setGetTagValues={setGetTagValuesOutside}
                setSearchbox={setSearchboxOutside}
            />
        </div>
        </>
    )
}

export default Index
